.size-panel {

}

.palette-panel {

}

.palette-editor {
    display: flex;
    flex-direction: row;
}

.color-editor {
    display: flex;
    flex-direction: column;
}

.color-preview {
    flex: 1 0 20%;
    width: 8vh;
    height: 8vh;
    border: .2vh solid;
}

.palette-container_admin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    border: 1px solid;
    background-color: white;

    width: 30vw;
    height: 20vh;

    overflow-y: scroll;
}

.color-choice_admin {
    width: 10vh;
    height: 4vh;
    border: .1vh solid;
    flex-shrink: 0;
}