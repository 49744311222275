.header-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.toggle-button {
    align-items: center;
    border: .4vh solid !important;
}

.toggle-button img {
    width: 4vh;
    height: 4vh;

    image-rendering: pixelated;
}

.header-elements_container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 20vh;
    position: fixed;
    top: 8.2vh;
    right: -20vh;    
    transition: right 0.25s ease-in-out;
}

.header-elements_container.show {
    right: 0;
}

.header-elements_container button {
    margin: 0.25vw;
}
