#btn-closemodal {
    position: absolute;

    margin-right: 1vh;
    margin-top: 1vh;

    top: 0;
    right: 0;
}

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0,0,0,0.5);

    cursor: default;

    z-index: 1;
}

.modal-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    width: fit-content;
    height: fit-content;

    background-color: white;
    border-radius: 1vw;
    border: 3px solid black;
}

.modal-elements .form-group {
    margin-bottom: 1vh;
}

.modal-elements h1,
.modal-elements form {
    margin-left: 2vw;
    margin-right: 2vw;
}

.btn-submit_container {
    display: flex;
    justify-content: center;
}