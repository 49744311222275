.main-board {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F3F3; 
    cursor: none;
}

.dropdown-menu {
    cursor: pointer;
}

#move-controller {
    position: relative;
    margin-inline: auto; 
    width: fit-content;
    border: 10px solid;
}
#zoom-controller {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    zoom: 1;
    z-index: 0;
}

.bottom-container {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;

    align-items: center;
    width: 87%;
}

@media (max-width: 768px) {
    .main-board {
        cursor: pointer;
    }
}