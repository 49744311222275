.palette-container {
    display: flex;
    flex-direction: row;
    
    border: 1px solid;
    background-color: white;

    width: 100%;
    height: max-content;

    overflow-x: scroll;
}

.color-choice {
    width: 10vh;
    height: 4vh;
    border: .1vh solid;
    flex-shrink: 0;
}