#drawing-board,
#canvas-cursor {    
    image-rendering: pixelated;
}

#cell {
    background-color: white;
    width: 2%;
    height: 2%;
}

#drawing-board {
    background-color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
}



#canvas-cursor {
    position: absolute;
    pointer-events: none;

    /* disable image selection so user can click multiple times without highlighting the image */
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}