:root {
    --cursor-size: 16px;
}

html, body, #root {
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;

    touch-action: none
}

.bot {
    bottom: 2vh;
    margin: 0;
    position: fixed;
}

.top {
    top: 2vh;
    margin: 0;
    position: fixed;
}

.left {
    left: 2vh;
    margin: 0;
    position: fixed;
}

.right {
    right: 2vh;
    margin: 0;
    position: fixed;
}
