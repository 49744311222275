.cellinfos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: fit-content;
    background-color: white;

    border-radius: .7vw;
    border: .4vh solid black;
}

.cellinfos-container * {
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 0.5vh;
}